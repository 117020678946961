manageSingle();

function manageSingle(){
  fairSingle();
}

function fairSingle(){
  if (!$('.o-detail-fair').length) return;
  const single = $('.o-detail-fair');
  const reserve = single.find('.o-reserve-latest__item');
  for (let i = 0; i < reserve.length; i++) {
    const date = reserve.eq(i).attr('data-date');
    single.find('.c-calendar td[data-date="'+date+'"]').addClass('-active');

    if (reserve.eq(i).hasClass('-current')) {
      single.find('.c-calendar td[data-date="'+date+'"]').addClass('-current');
    }
  }
  if (reserve.length <= 1) {
    $('.o-detail-fair .o-grp-calendar').addClass('pc');
  }

  single.find('.c-calendar td[data-date].-active').click(function(){
    $(this).toggleClass('-current');
    const date = $(this).attr('data-date');
    single.find('.o-reserve-latest__item[data-date="'+date+'"]').stop().fadeToggle();
  });
}