modal();

function modal(){
  modalDress();
  brandDress();
  modalReserve();
  modalDiagnosis();
  modalCalendar();
  modalChange();
}



function modalDress(){
  let modal = '<div class="c-modal-dress"></div>';
  let scroll = 0;

  if (!$('.c-modal-dress').length) {
    $('.l-wrapper').append(modal);
  }
  $(document).on('click', '.modalDress', function(){
    const item = $(this);
    scroll = $(window).scrollTop();
    $('body').css({
      'position': 'fixed',
      'top': -1*scroll+'px',
    });

    _open();

    function _open() {
      $('.c-modal-dress').scrollTop(0);
      modal = '\
          <div class="c-overlay"></div>\
          <div class="c-btn-close"></div>\
          <div class="c-modal-dress__img">\
            <figure>\
              <img src="'+_attr('image')+'" alt="">\
            </figure>\
            <div class="c-modal-dress__meta">\
              ';
      if (_attr('brand') != 'null') {
        modal += '\
              <p class="c-copy-small">'+_attr('brand')+'</p>';
      }
      modal += '\
              <div class="c-modal-dress__data">';
      if (_attr('color') != '') {
        modal += '\
                <dl>\
                  <dt>COLOR</dt>\
                  <dd>'+_attr('color')+'</dd>\
                </dl>';
      }
      if (_attr('line') != 'null') {
        modal += '\
                <dl>\
                  <dt>LINE</dt>\
                  <dd>'+_attr('line')+'</dd>\
                </dl>';
      }
      modal += '\
              </div>\
            </div>\
          </div>';

      $('.c-modal-dress').html(modal).stop().addClass('-active');
    }

    function _attr(slug){
      return item.attr('data-'+slug);
    }
  });

  $(document).on('click', '.c-modal-dress .c-btn-close, .c-modal-dress .c-overlay', function(){
    $('.c-modal-dress').stop().removeClass('-active');
    $('body').css({
      'position': 'static',
      'top': '',
    });
    $(window).scrollTop(scroll);
  });
}



function brandDress(){
  if (!$('.modalBrand').length) return;
  const items = DRESS_ITEM_ELEMENT;
  let modal = '<div class="c-modal-brand"></div>';
  let scroll = 0;

  if (!$('.c-modal-brand').length) {
    $('.l-wrapper').append(modal);
  }
  $(document).on('click', '.modalBrand', function(){
    const $this = $(this);
    const brand = $this.attr('data-brand');
    scroll = $(window).scrollTop();
    $('body').css({
      'position': 'fixed',
      'top': -1*scroll+'px',
    });

    _open();

    function _open() {
      const logo = $this.find('.o-lead-brand__logo img').attr('src');
      const label = $this.find('.o-lead-brand__logo span').text();
      const copy = $this.attr('data-copy');
      const desc = $this.attr('data-desc');
      $('.c-modal-brand').scrollTop(0);
      modal = '\
          <div class="c-overlay"></div>\
          <div class="c-btn-close"></div>\
          <div class="l-inner">\
            <div class="c-modal-brand__textarea">\
              <figure class="c-modal-brand__logo">\
                <img src="'+logo+'" alt="">\
                <figcaption class="c-desc">【'+label+'】</figcaption>\
              </figure>\
              <p class="c-copy-basic">'+copy+'</p>\
              <p class="c-desc">'+desc+'</p>\
            </div>\
            <div class="c-modal-brand__slider">\
          ';
      let cnt = 0;
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        if (item.brand == brand) {
          cnt++;
          modal += '\
            <figure>\
              <img src="'+item.image+'" alt="">\
              <figcaption>MODEL NAME | '+item.model+'</figcaption>\
            </figure>\
          ';
        }
      }
      modal += '\
            </div>\
            <div class="c-modal-brand__controller">\
              <p class="c-modal-brand__counter">\
                <span class="c-modal-brand__numer">01</span>\
                <span class="c-modal-brand__denom">'+zeroPadding(cnt,2)+'</span>\
              </p>\
              <p class="c-modal-brand__name"></p>\
            </div>\
          </div>\
          ';

      $('.c-modal-brand').html(modal).stop().addClass('-active');
      const slider = $('.c-modal-brand__slider');
      slider.slick({
        'swipeToSlide': true,
        'autoplay': false,
        'appendArrows': $('.c-modal-brand__controller'),
        'prevArrow': '<div class="c-modal-brand__arrow -prev">',
        'nextArrow': '<div class="c-modal-brand__arrow -next">',
      });
      _dataSetting(0);
      slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
        _dataSetting(nextSlide);
      });

      function _dataSetting(n){
        const slide = slider.find('.slick-slide').not('.slick-cloned');
        const current = slide.eq(n);
        const numer = $('.c-modal-brand__numer');
        const title = current.find('figcaption').text();
        const titlePlace = $('.c-modal-brand__name');
        numer.text(zeroPadding(n+1,2));
        titlePlace.html(title);
      }
    }

  });

  $(document).on('click', '.c-modal-brand .c-btn-close', function(){
    $('.c-modal-brand').stop().removeClass('-active');
    $('body').css({
      'position': 'static',
      'top': '',
    });
    $(window).scrollTop(scroll);
  });
}


function zeroPadding(NUM, LEN){
  return ( Array(LEN).join('0') + NUM ).slice( -LEN );
}

function modalReserve(){
  let scroll = 0;

  $(document).on('click', '.modalReserve', function(e){
    e.preventDefault();
    scroll = $(window).scrollTop();
    $('body').css({
      'position': 'fixed',
      'top': -1*scroll+'px',
    });
    _open();

    function _open() {
      $('.c-modal-reserve').scrollTop(0);
      $('.c-modal-reserve').stop().addClass('-active');
    }
  });
  $(document).on('click', '.c-modal-reserve .c-btn-close', function(){
    $('.c-modal-reserve').stop().removeClass('-active');
    $('body').css({
      'position': 'static',
      'top': '',
    });
    $(window).scrollTop(scroll);
  });
}

function modalDiagnosis(){
  let scroll = 0;
  $(document).on('click', '.modalDiagnosis', function(e){
    e.preventDefault();
    scroll = $(window).scrollTop();
    $('body').css({
      'position': 'fixed',
      'top': -1*scroll+'px',
    });
    _open();

    function _open() {
      $('.c-modal-diagnosis').scrollTop(0);
      $('.c-modal-diagnosis').stop().addClass('-active');
    }
  });
  $(document).on('click', '.c-modal-diagnosis .c-btn-close', function(){
    $('.c-modal-diagnosis').stop().removeClass('-active');
    $('body').css({
      'position': 'static',
      'top': '',
    });
    $(window).scrollTop(scroll);
  });
}


function modalCalendar(){
  let scroll = 0;

  $(document).on('click', '.modalCalendar', function(e){
    e.preventDefault();
    scroll = $(window).scrollTop();
    $('body').css({
      'position': 'fixed',
      'top': -1*scroll+'px',
    });
    _open();

    function _open() {
      $('.c-modal-calendar').scrollTop(0);
      $('.c-modal-calendar').stop().addClass('-active');
    }
  });
  $(document).on('click', '.c-modal-calendar .c-btn-close, .c-modal-calendar__btn .c-btn', function(e){
    e.preventDefault();
    $('.c-modal-calendar').stop().removeClass('-active');
    $('body').css({
      'position': 'static',
      'top': '',
    });
    $(window).scrollTop(scroll);
  });
}


function modalChange() {
  $(document).on('click', '.modalChange', function(e){
    e.preventDefault();
    const from = $(e.target).attr('data-modal-from');
    const to = $(e.target).attr('data-modal-to');
    console.log($('.c-modal-'+from));
    console.log($('.c-modal-'+to));
    $('.c-modal-'+from).stop().removeClass('-active');
    $('.c-modal-'+to).stop().addClass('-active');
  });
}