// common js

let windowWidth = window.innerWidth;
const windowSm = 750;

// windowWidth = window.innerWidth;
// if (windowWidth > windowSm) {
// } else {
// }

common();
function common(){
  if ($('body').hasClass('home')) {
    loadingAnimation();
  }else{
    loadingDone();
  }
  instagramFeed();
  bgImg();
  sliderSP();
  sliderPC();
  navMenu();
  spMenu();
  accordionSP();
  headerScroll();
  sliderFair();
  calendarSlider();
  bgScrollParallax();
  archiveAccordion();
  pagetop();
  accordionBtn();
  listTglItem();
  navAnchor();
  btnRowAnchor();
  reportMore();
  infoAnchorLink();
  fairAnchorLink();
  accessAnchorLink();
}


function loadingAnimation(){

  const images = $('img');
  const count = $('#count');
  let current;
  let cnt = 0;

  for (let i = 0; i < images.length; i++) {
    let img = new Image();
    img.onload = function() {
      cnt += 1;
    }
    img.onerror = function() {
      cnt += 1;
    }
    img.src = images[i].src;
  };

  _animation();
  function _animation(){
    current = Math.floor(cnt / images.length * 100);
    count.text(current);
    const rotate01 = current >= 50 ? 180 : 360 * current / 100;
    const rotate02 = current >= 50 ? 360 * (current - 50) / 100 : 0;
    $('#circle01').css({
      'transform': 'rotate('+rotate01+'deg)',
    });
    $('#circle02').css({
      'transform': 'rotate('+rotate02+'deg)',
    });
    if (cnt == images.length) {
      cancelAnimationFrame(_animation);
      setTimeout(function(){
        $('#loading').addClass('-loaded');
      },200);
      setTimeout(function(){
        $('body').addClass('-loaded');
      },600);
    }else{
      requestAnimationFrame(_animation);
    }
  }
}


function loadingDone(){
  let flg = true;
  $(window).on('load', function(){
    _loaded();
  });
  setInterval(function(){
    _loaded();
  }, 5000);

  function _loaded(){
    if (flg){
      $('body').addClass('-loaded');
      flg = false;
    }
  }
}



function bgImg(){
  $('.bgImg').each(function(){
    const img = $(this).find('img.bgSrc');

    if (!img.length) return;

    const imgURL = img.attr('src');

    $(this).css({
      'background-image': 'url('+imgURL+')',
    });
  });
}


function sliderSP(){
  const sliders = $('.c-slider-sp');
  const widthPC = windowSm;
  const widthSP = widthPC+1;

  _matchFunc();
  function _matchFunc(){
    if(window.matchMedia("(max-width:"+ widthPC +"px)").matches){
      //SP
      sliders.each(function(){
        const slider = $(this);
        const colNum = slider.attr('data-col') ? Number(slider.attr('data-col')) : 1;
        const sclNum = slider.attr('data-scroll') ? Number(slider.attr('data-scroll')) : 1;
        const auto = slider.attr('data-auto') ? slider.attr('data-auto') : false;
        slider.slick({
          'slidesToShow': colNum,
          'slidesToScroll': sclNum,
          'variableWidth': true,
          'swipeToSlide': true,
          'autoplay': auto,
          'prevArrow': '<div class="c-slider-sp__arrow -prev">',
          'nextArrow': '<div class="c-slider-sp__arrow -next">',
        });
      });
    }
    if(window.matchMedia("(min-width:"+ widthSP +"px)").matches){
      //PC
      $('.c-slider-sp.slick-initialized').slick('unslick');
    }
  }
  window.matchMedia("(max-width:"+ widthPC +"px)").addListener(_matchFunc);
}


function sliderPC(){
  const sliders = $('.c-slider-pc');
  const widthPC = windowSm;
  const widthSP = widthPC+1;

  _matchFunc();
  function _matchFunc(){
    if(window.matchMedia("(max-width:"+ widthPC +"px)").matches){
      //SP
      $('.c-slider-pc.slick-initialized').slick('unslick');
    }
    if(window.matchMedia("(min-width:"+ widthSP +"px)").matches){
      //PC
      sliders.each(function(){
        const slider = $(this);
        const colNum = slider.attr('data-col') ? Number(slider.attr('data-col')) : 1;
        const sclNum = slider.attr('data-scroll') ? Number(slider.attr('data-scroll')) : 1;
        const auto = slider.attr('data-auto') ? slider.attr('data-auto') : false;
        slider.slick({
          'slidesToShow': colNum,
          'slidesToScroll': sclNum,
          'variableWidth': true,
          'swipeToSlide': true,
          'autoplay': auto,
          'prevArrow': '<div class="c-slider-pc__arrow -prev">',
          'nextArrow': '<div class="c-slider-pc__arrow -next">',
        });
      });
    }
  }
  window.matchMedia("(max-width:"+ widthPC +"px)").addListener(_matchFunc);
}





function instagramFeed(){
  const content = $('#instagramFeed');

  if (!content.length) return;

  _getFeedData();

  function _getFeedData(){
    const tempDir = $('#instagramFeed').attr('data-temp-dir');
    $.ajax({
      type: 'GET',
      url: tempDir+'/instagram_feed.php',
      dataType: "json",
      error: function() {
        console.log('instagram feed error');
      },
      success: function(json) {
        const data = json.media.data;
        let html = '';
        for (let i = 0; i < data.length; i++) {
          const d = data[i];
          html += '\
            <a href="'+d.permalink+'">\
              <figure style="background-image:url('+d.media_url+')">\
              </figure>\
            </a>\
          ';
        }
        $('#instagramFeed .o-lead-instagram__feed__item').append(html);



        const feed = content.find('.o-lead-instagram__feed');
        const feedW = feed.width();
        const feedHtml = feed.html();
        const dW = window.innerWidth;

        const feedChildW = feed.children().innerWidth();
        let position = 0;
        _clone();
        _animation();
        
        function _clone(){
          const rates = Math.ceil(dW*2 / feedW);

          let pushHtml = '';
          for (var i = 0; i < rates+1; i++) {
            pushHtml += feedHtml;
          }
          feed.html(pushHtml);
        }


        function _animation(){
          position += .5;
          if (position > feedChildW) {
            position = 0;
          }
          feed.css({
            'transform': 'translate(-'+position+'px)',
          });
          requestAnimationFrame(_animation);
        }
      }
    });
  }


}



function navMenu(){
  let is_parent = true;
  $('.o-gnav').on({
    'mouseleave': function(event){
      const $target = $(event.target);
      is_parent = $target.parents('.o-gnav__child').length ? false : true;
      _hide($target);
    },
    'mouseenter': function(event){
      const $target = $(event.target);
      is_parent = $target.parents('.o-gnav__child').length ? false : true;
      _show($target);
    },
  }, '.o-nav-menu, .o-gnav__item a');

  function _hide(item){
    if (_menuSelector(item).length) {
      windowWidth = window.innerWidth;
      if (windowWidth > windowSm) {
        $('.l-header').removeClass('-menu');
        _menuSelector(item).removeClass('-show');
      }
    }
  }
  function _show(item){
    if (_menuSelector(item).length) {
      windowWidth = window.innerWidth;
      if (windowWidth > windowSm) {
        $('.l-header').addClass('-menu');
        _menuSelector(item).addClass('-show');
      }
    }
  }

  function _menuSelector(item){
    if (is_parent) {
      return item.closest('.o-gnav__item').siblings('.o-gnav__child');
    }else{
      return item.closest('.o-gnav__child');
    }
  }
}




function spMenu(){
  let scroll = 0;
  $('.c-btn-menu').click(function(){
    if ($(this).hasClass('-active')) {
      _close();
    }else{
      _open();
    }
  });

  function _open(){
    scroll = $(window).scrollTop();
    $('.c-btn-menu').addClass('-active');
    $('.l-header .c-logo').addClass('-white');
    $('.o-gnav').stop().fadeIn();
    $('body').css({
      'position': 'fixed',
      'top': -1*scroll+'px',
    });
  }
  function _close(){
    $('.c-btn-menu').removeClass('-active');
    $('.l-header .c-logo').removeClass('-white');
    $('.o-gnav').stop().fadeOut();
    $('body').css({
      'position': 'static',
      'top': '',
    });
    $(window).scrollTop(scroll);
  }
}



function accordionSP(){
  $('.c-link-sp-accordion').on({
    'click': function(event){
      const $target = $(event.target);
      const label = $target.closest('.c-link-sp-accordion__label');
      const list = label.siblings('.c-link-sp-accordion__list');
      if (!list.length) return;
      if ($target.hasClass('-active')) {
        _close($target, list);
      }else{
        _open($target, list);
      }
    },
  }, '.-btn');

  function _open(a,b){ // a=target , b=list
    a.addClass('-active');
    b.stop().slideDown();
  }
  function _close(a,b){ // a=target , b=list
    a.removeClass('-active');
    b.stop().slideUp();
  }
}



function headerScroll(){
  const header = $('.l-header');
  const mv = $('.o-mainvisual');
  $(window).on('scroll', function(){
    const mvH = mv.innerHeight();
    const scroll = $(window).scrollTop();
    if (mv.length) {
      if (mvH*0.8 <= scroll) {
        header.addClass('-fixed');
      }else{
        header.removeClass('-fixed');
      }
    }
    header.css({
      'left': -window.scrollX + 'px',
    });
  });
}



function sliderFair(){
  const slider = $('.c-slider-fair');
  slider.slick({
    'fade': true,
    'appendArrows': '.c-slider-fair-dot',
    'prevArrow': '<div class="c-slider-fair-dot__arrow -prev"></div>',
    'nextArrow': '<div class="c-slider-fair-dot__arrow -next"></div>',
    'dots': true,
    'appendDots': '.c-slider-fair-dot',
  });
  _footSetting(0);
  slider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
    _footSetting(nextSlide);
  });

  function _footSetting(n){
    const slide = slider.find('.slick-slide').not('.slick-cloned');
    const current = slide.eq(n);
    const title = current.find('.c-copy-basic').text();
    const link = current.find('a').attr('href');
    const titlePlace = $('.c-slider-fair-dot__ttl');
    titlePlace.html('<a href="'+link+'">'+title+'</a>');
  }
}

function calendarSlider(){
  const slider = $('.c-calendar');
  slider.each(function(){
    $(this).slick({
      'fade': true,
      'prevArrow': '<div class="c-calendar__arrow -prev"></div>',
      'nextArrow': '<div class="c-calendar__arrow -next"></div>',
      'infinite': false,
    });
  });
}



// bg scroll parallax
function bgScrollParallax(){
  const deep = .4;
  const target = $('.prlxBg');
  const winH = window.innerHeight;

  let offsetArray = [];

  $(window).on('load resize', function(){
    offsetArray = [];
    for (let i = 0; i < target.length; i++) {
      offsetArray.push($(target[i]).offset().top);
    }
    // initial
    _positioning();
  });

  $(window).on('scroll',function(){
    // scrolling
    _positioning();
  });

  function _positioning(){
    if (!offsetArray) return;
    for (let i = 0; i < target.length; i++) {
      const $this = $(target[i]);
      const scroll = $(window).scrollTop();
      const offsetTop = offsetArray[i];
      if (offsetTop - winH < scroll && scroll < offsetTop + winH) {
        const shift = scroll - offsetTop + winH*.5;
        $this.css({
          'transform': 'translateY('+ 1 * deep * shift + 'px)',
        });
      }
    };
  }
}

function archiveAccordion(){
  $('.listAccordion').on('click', function () {
    if($(this).hasClass('-active')){
      $(this).removeClass('-active');
      $(this).find('ul').stop().slideUp();
    }else{
      $(this).addClass('-active');
      $(this).find('ul').stop().slideDown();
    }
    // return false;
  });
}



function accordionBtn(){
  $('.accordionBtn').click(function () {
    $(this).next('ul').slideToggle();
  });
}

function listTglItem(){
  $(document).on('click', '.list-tgl__item__link', function () {
    $(this).next().slideToggle('fast');
    $(this).toggleClass('-active');
  });
}


function pagetop(){
  $('.c-btn-pagetop').click(function(){
    $('html,body').animate({scrollTop:0}, 700, 'swing');
  });
}


function navAnchor(){
  $('.c-section-nav a[href^="#"]').click(function() {
    var speed = 400; 
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top - 100;
    $('body,html').animate({scrollTop:position}, speed, 'swing');
    return false;
  });
}


function btnRowAnchor(){
  $('.c-btn-row a[href^="#"]').click(function() {
    var speed = 400;
    var href= $(this).attr("href");
    var target = $(href == "#" || href == "" ? 'html' : href);
    var position = target.offset().top - 100;
    $('body,html').animate({scrollTop:position}, speed, 'swing');
    return false;
  });
}


function reportMore(){
  $('.c-box-report__article .c-btn-more').click(function() {
    const qa = $('.c-box-report__article .c-box-report__txt > div');
    qa.css({
      'display': ''
    });
    $(this).fadeOut();
    return false;
  });
}


function infoAnchorLink(){
  if (!$('.o-detail-information').length) return;
  $('a[href^="#"]').click(function(){
    const speed = 500;
    const href= $(this).attr("href");
    const target = $(href == "#" || href == "" ? 'html' : href);
    const headSpace = $('.l-header').innerHeight() + 16;
    const position = target.offset().top - headSpace;
    $("html, body").animate({scrollTop:position}, speed, "swing");
    return false;
  });
}


function fairAnchorLink(){
  if (!$('.o-detail-fair').length) return;
  $('a[href^="#"]').click(function(){
    const speed = 500;
    const href= $(this).attr("href");
    const target = $(href == "#" || href == "" ? 'html' : href);
    const headSpace = $('.l-header').innerHeight() + 16;
    const position = target.offset().top - headSpace;
    $("html, body").animate({scrollTop:position}, speed, "swing");
    return false;
  });
}


function accessAnchorLink(){
  if (!$('.o-access-info').length) return;
  $('a[href^="#"]').click(function(){
    const speed = 500;
    const href= $(this).attr("href");
    const target = $(href == "#" || href == "" ? 'html' : href);
    const headSpace = $('.l-header').innerHeight() + 16;
    const position = target.offset().top - headSpace;
    $("html, body").animate({scrollTop:position}, speed, "swing");
    return false;
  });
}