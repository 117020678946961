caseSlider();
function caseSlider() {
  if(!$('.o-unit-case').length) return;
  $('.o-unit-case').each(function() {
    const main = $(this).find('.o-unit-case__after__main');
    const nav = $(this).find('.o-unit-case__after__nav');
    main.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: true,
      appendDots: nav,
      fade: true,
    });

    let num = 0;
    main.find('li').each(function() {
      nav.find('li').eq(num).append($(this).find('figure').clone());
      console.log(nav.find('li').eq(num));
      num++;
    });
  });
}