dateSortFair();

function dateSortFair(){
  const modal = $('.c-modal-reserve');
  const datePanel = modal.find('.c-calendar td[data-date]');
  $('.c-modal-reserve__fair .c-btn').css({
    'opacity': '.5',
    'pointer-events': 'none',
  });
  datePanel.click(function(){
    $(this).toggleClass('-current');
  });

  let calendarArray = [];
  modal.click(function(){
    calendarArray = [];
    const dateCurrent = modal.find('.c-calendar td.-current');
    for (let i = 0; i < dateCurrent.length; i++) {
      const date = dateCurrent.eq(i).attr('data-date');
      calendarArray.push(date);
    }

    if (calendarArray.length != 0) {
      $('.c-modal-reserve__fair .c-btn').css({
        'opacity': '',
        'pointer-events': '',
      });
    }else{
      $('.c-modal-reserve__fair .c-btn').css({
        'opacity': '.5',
        'pointer-events': 'none',
      });
    }
  });

  $('.c-modal-reserve__fair .c-btn').click(function(e){
    e.preventDefault();

    Cookies.set('fairCalendar', JSON.stringify(calendarArray));

    location = $(this).children('a').attr('href');
  });
}