manageArchive();

function manageArchive(){
  dressArchive();
  fairArchive();
  anniversaryArchive();
}

function dressArchive(){
  if (!$('.o-archive-dress').length) return;
  const items = DRESS_ITEM_ELEMENT;

  _initial();

  function _initial(){
    _export(items);
  }


  $('.o-archive-dress__sort li a').click(function(e){
    e.preventDefault();
    $(this).parents('.o-archive-dress__sort').find('li').removeClass('-current');
    $(this).parent('li').addClass('-current');
    const slug = $(this).attr('data-category');
    _export(_filter(slug));
  });


  $('#dressArchive').siblings('.c-btn').click(function(){
    $(this).hide();
    $('#dressArchive li').fadeIn();
  });


  function _filter(slug){
    if (slug == 'all') return items;

    const result = items.filter(function(dress){
      return dress.category == slug;
    });

    return result;
  }


  function _export(array){
    const length = 9;
    // const length = 0;
    let html = '';
    let showStyle = '';
    let nowCount = 0;
    for (let i = 0; i < array.length; i++) {
      const item = array[i];
      if (!item.show) continue;
      nowCount++;
      if (nowCount > length) {
        showStyle = 'display:none;';
      }
      // if (!item.show) {
      //   console.log(item.show);
      // }
      html += '\
        <li class="o-archive-dress__item modalDress"\
          data-color="'+item.color+'"\
          data-line="'+item.line+'"\
          data-image="'+item.image+'"\
          data-brand="'+item.brandLabel+'"\
          style="background-image: url('+item.image+');'+showStyle+'"\
        >\
        </li>\
      ';
    }
    if (!array.length) {
      html = '<p class="c-desc u-center">該当するドレスは見つかりませんでした。</p>'
    }
    $('#dressArchive').html(html);
    if (nowCount > length) {
      $('#dressArchive').siblings('.c-btn').stop().fadeIn();
    }else{
      $('#dressArchive').siblings('.c-btn').stop().fadeOut();
    }
  }
}




function fairArchive(){
  if (!$('#archive').length) return;
  const btn = $('#viewmore');
  const fair = $('#archive .c-item-fair');
  const allCount = fair.length;
  const perCount = 10;
  let calendarArray = [];
  let showCount = 0;
  let scroll = $(window).scrollTop();

  let fairCalendar = Cookies.get('fairCalendar');
  let fairType = Cookies.get('fairType');
  let fairKey = Cookies.get('fairKey');

  if (fairCalendar && fairKey) {
    fairCalendar = JSON.parse(fairCalendar);
    fairKey = JSON.parse(fairKey);

    _postSet('diagnosis');

    Cookies.remove('fairCalendar');
    Cookies.remove('fairKey');
    $(window).scrollTop($('.o-archive-fair').offset().top);
  }else if(fairCalendar){
    calendarArray = JSON.parse(fairCalendar);

    _postSet('date');

    Cookies.remove('fairCalendar');
    $(window).scrollTop($('.o-archive-fair').offset().top);
  }else{
    _postSet('all');
  }


  btn.click(function(e){
    e.preventDefault();
    scroll = $(window).scrollTop();
    _viewMore();
  });

  function _viewMore(){
    const active = $('#archive').find('.c-item-fair.-active');
    for (let i = 0; i < perCount; i++) {
      active.eq(showCount).fadeIn();
      showCount++;
    }
    if (showCount >= active.length) {
      btn.hide();
    }
    $(window).scrollTop(scroll);
  }


  $('.c-list-tab a').click(function(e){
    e.preventDefault();
    const list = $(this).parent('li');
    $('.c-list-tab li').removeClass('-current');
    list.addClass('-current');
    const sort = $(this).attr('data-sort');
    _postSet(sort);
  });


  $('.c-modal-calendar .c-calendar td[data-date]').click(function(){
    const date = $(this).attr('data-date');
    $(this).toggleClass('-current');
  });

  $('.c-modal-calendar__btn .c-btn').click(function(){
    calendarArray = [];
    const current = $('.c-modal-calendar .c-calendar td[data-date].-current');
    for (let i = 0; i < current.length; i++) {
      calendarArray.push(current.eq(i).attr('data-date'));
    }
    _postSet('date');
  });


  function _postSet(type){
    showCount = 0;
    $('#archive').removeClass('-all -weekdays -holiday -night -date -diagnosis');
    $('#archive').addClass('-'+type);
    if (type == 'all') {
      fair.addClass('-active');
    }else{
      fair.removeClass('-active');
      fair.css({
        'display': 'none',
      });
      if (type == 'date') {
        for (let l = 0; l < calendarArray.length; l++) {
          for (let i = 0; i < fair.length; i++) {
            if(fair.eq(i).attr('data-date') == calendarArray[l]) {
              fair.eq(i).addClass('-active');
            }
          }
        }
        $('.c-list-tab li').removeClass('-current');
      }else if(type == 'diagnosis'){
        _diagnosis();
      }else{
        for (let i = 0; i < fair.length; i++) {
          if (type == 'weekdays'){
            if(fair.eq(i).attr('data-day') === 'weekdays') {
              fair.eq(i).addClass('-active');
            }
          }else if(type == 'holiday'){
            if(fair.eq(i).attr('data-day') === 'holiday') {
              fair.eq(i).addClass('-active');
            }
          }else if(type == 'night'){
            if(fair.eq(i).attr('data-type') === 'fair_night') {
              fair.eq(i).addClass('-active');
            }
          }
        };
      }
    }


    function _diagnosis(){
      const orderFairType = [
        'fair_season',
        'fair_preafter',
        'fair_special',
        'fair_party',
        'fair_demo',
        'fair_night',
        'fair_maternity',//type
        'fair_online',
      ];
      const orderFairkey = [
        'fair_privilege',
        'fair_mock',
        'fair_food',
        'fair_fittinh',
        'fair_night',
        'fair_matanity',//key
        'fair_online',
        'fair_consult',
      ];
      let setFairKey = false;
      for (let l = 0; l < fairCalendar.length; l++) {
        for (let i = 0; i < fair.length; i++) {
          if(fair.eq(i).attr('data-date') == fairCalendar[l]) {
            fair.eq(i).addClass('-active');
          }
        }
      }
      const sortDateFair = $('#archive').find('.c-item-fair.-active');
      for (let i = 0; i < orderFairkey.length; i++) {
        for (let l = 0; l < fairKey.length; l++) {
          if (orderFairkey[i] == fairKey[l]) {
            if (!setFairKey) {
              setFairKey = orderFairkey[i];
            }
          }
        }
      }
      for (let i = 0; i < sortDateFair.length; i++) {
        if (setFairKey != sortDateFair.eq(i).attr('data-key')) {
          sortDateFair.eq(i).removeClass('-active');
        }
      }
      const sortKeyFair = $('#archive').find('.c-item-fair.-active');
      if (!sortKeyFair.length && sortDateFair.length) {
        for (let l = 0; l < orderFairType.length; l++) {
          for (let i = 0; i < sortDateFair.length; i++) {
            if (sortDateFair.eq(i).attr('data-key') == orderFairkey[l]) {
              sortDateFair.eq(i).addClass('-active');
            }
          }
          const sortTypeFair = $('#archive').find('.c-item-fair.-active');
          if (sortTypeFair.length) {
            break;
          }
        }
      }
      $('.c-list-tab li').removeClass('-current');
    }

    const active = $('#archive').find('.c-item-fair.-active');
    if (active.length) {
      $('#archive').removeClass('-not-found');
    }else{
      $('#archive').addClass('-not-found');
    }
    for (let i = 0; i < perCount; i++) {
      active.eq(i).fadeIn();
      showCount++;
    }
    if (active.length <= perCount) {
      btn.hide();
    }else{
      btn.show();
    }
  }
}


function anniversaryArchive(){
  const btnSort = $('.btn-sort');
  const itemsSort = $('.btn-sort ul');
  const itemSort = $('.btn-sort ul a');
  const list = $('.o-detail-gift #list');
  const posts = $('.o-list-gift__item');
  const btnMore = $('.c-btn.-ghost.-loadmore');

  let postsArray = [];
  for (let i = 0; i < posts.length; i++) {
    const $this = posts.eq(i);
    const price = $this.attr('data-price');
    const ranking = $this.attr('data-ranking');
    const post = {
      'price': price,
      'ranking': ranking,
      'html': $this.prop('outerHTML'),
    };
    postsArray.push(post);
  }

  _setting();
  itemSort.click(function(e){
    e.preventDefault();
    $(this).parents('.btn-sort').find('ul').slideToggle();
    const text = $(this).text();
    $(this).parents('.btn-sort').children('p').text(text);

    const sortKey = $(this).attr('data-sort');

    
    const sortedArray = _sort(sortKey);
    let html = '';
    for (let i = 0; i < sortedArray.length; i++) {
      html += sortedArray[i]['html'];
    }
    list.html(html);

    _setting();
  });

  btnMore.click(function(e){
    e.preventDefault();
    list.find('.o-list-gift__item.-hide').removeClass('-hide');
    btnMore.hide();
  });

  function _sort(key){
    let sortArray = postsArray;
    let orderby = undefined;
    let order = 1;

    if (key == 'price-low' || key == 'price-high') {
      orderby = 'price';
    }else{
      orderby = key;
    }

    if (key == 'price-high') {
      order = -1;
    }

    sortArray.sort(function(a,b){
      if( a[orderby] < b[orderby] ) return -1*order;
      if( a[orderby] > b[orderby] ) return 1*order;
      return 0;
    });

    return sortArray;
  }

  function _setting(){
    const itemMore = list.find('.o-list-gift__item:nth-child(n+4)');
    if (itemMore.length) {
      itemMore.addClass('-hide');
      btnMore.show();
    }else{
      btnMore.hide();
    }
  }
}