itemBg();

function itemBg(){
  let html = '';
  for (var i = 0; i < 6; i++) {
    html += '<figure></figure>';
  }

  $('.itemBg').append(html);
}
