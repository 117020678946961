// // top page js

topFunction();

function topFunction() {
  if (!$('body').hasClass('home')) return;
  mvFitting();
  mainvisual();
  boxPickup();
}


function mvFitting(){
  const slider = document.getElementsByClassName('o-mainvisual');
  if (!slider.length) return;
  _fitHightSP();
  // window.addEventListener('resize', function(){
  //   _fitHightSP();
  // });
  function _fitHightSP(){
    let windowWidth = window.innerWidth;
    if (windowWidth > windowSm) return;
    const dH = document.documentElement.clientHeight - 70;
    slider[0].style.height = dH+'px';
  }
}


function mainvisual(){
  const slider = $('.o-mainvisual__slider');
  const slide = $('.o-mainvisual__slider li');
  const changeDuration = 5000;
  const animateDuration = 2000;
  const fpsRate = 200;

  let flg = true;

  // 背景画像セット
  slide.find('.o-mainvisual__slide').each(function(){
    const img = $(this).attr('data-bg');
    $(this).css({
      'background-image': 'url('+img+')',
    });
    $(this).attr('data-bg', '');
  });

  // ドット生成
  _createDots();


  let nowNumber = 0;
  let nowSlide = slide.eq(nowNumber);
  let nextSlide = slide.eq(nowNumber+1);

  $(window).on('load', function(){
    _init();
  });

  function _init(){
    // 初回
    nowSlide.addClass('-scale -show');
    timerAnimateReset();

    // 起動用タイマー
    let timer = 0;
    const timerLimit = changeDuration;

    // タイマー自動カウント
    setInterval(function(){
      timer++;
      if (timer >= changeDuration/fpsRate) {
        if (!flg) return;
        nowSlide = slide.eq(nowNumber);
        nextSlide = slide.eq(_incNowNum());
        timerAnimateReset();
        _slideChange(nowSlide, nextSlide);
      }
    },fpsRate);


    // ドットクリック時
    const dots = '.o-mainvisual__dots .c-dot';
    $(document).on('click', dots, function(e){
      if (!flg) return;
      if (nowNumber == $(e.target).index()) return;
      timerAnimateReset();
      _slideChangeByDots(e.target);
    });


    // スライド変更function
    function _slideChange(before, after){
      _switchDots($(dots).eq(after.index()));
      after.addClass('-scale');
      after.addClass('-show');
      before.stop().removeClass('-show');
      setTimeout(function(){
        before.stop().removeClass('-scale');
      }, animateDuration);

      // 後処理
      timer = 0;
    }

    // タイマーアニメーション用
    function timerAnimateReset(){
      const timerHeight = $('.o-mainvisual__timer').height();
      const timerItem = $('.o-mainvisual__timer span');
      timerItem.stop().css({
        'height': timerHeight+'px',
      });
      timerItem.stop().animate({
        'height': '0px',
      },changeDuration, 'linear');
    }

    // ドットクリック処理
    function _slideChangeByDots(target){
      const $this = $(target);

      // ドットカレント切り替え
      _switchDots($this);

      // スライド変更
      nowSlide = slide.eq(nowNumber);
      nextSlide = slide.eq($this.index());

      _slideChange(nowSlide, nextSlide);

      // 後処理
      nowNumber = $this.index();
      timer = 0;
    }

    // ドットカレント切り替え
    function _switchDots(target){
      if (!flg) return;
      $(dots).removeClass('-current');
      target.addClass('-current');

      // アニメーション中切り替え停止
      flg = false;
      setInterval(function(){
        flg = true;
      },animateDuration);
    }

    // 現在スライド番号通常カウントアップ
    function _incNowNum(){
      nowNumber++;
      if (slide.length <= nowNumber) {
        nowNumber = 0;
      }
      return nowNumber;
    }
  }


  // ドット生成
  function _createDots(){
    const target = $('.o-mainvisual__dots');
    for (var i = 0; i < slide.length; i++) {
      // 1個目にカレント処理
      const currentClass = i == 0 ? ' -current' : '';
      target.append('<div class="c-dot'+currentClass+'"></div>');
    }
  }
}





function boxPickup(){
  $('.c-box-pickup').on({
    'click': function(event){
      const $target = $(event.target);
      const box = $target.closest('.c-box-pickup');
      const badge = $('.c-badge-pickup');
      box.hide();
      badge.show();
    },
  }, '.c-box-pickup__close');

  $('.o-mainvisual').on({
    'click': function(event){
      const badge = $(event.target);
      const box = badge.siblings('.c-box-pickup');
      box.show();
      badge.hide();
    },
  }, '.c-badge-pickup');
}
