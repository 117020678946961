diagnosisFair();

function diagnosisFair(){
  const modal = $('.c-modal-diagnosis');
  const datePanel = modal.find('.c-calendar td[data-date]');
  $('.c-btn.-diagnosis').css({
    'opacity': '.5',
    'pointer-events': 'none',
  });
  datePanel.click(function(){
    $(this).toggleClass('-current');
  });

  let calendarArray = [];
  let keyArray = [];
  let typeArray = [];
  let conditionsArray = [];


  _checkbox();
  modal.click(function(){
    calendarArray = [];
    const dateCurrent = modal.find('.c-calendar td.-current');
    for (let i = 0; i < dateCurrent.length; i++) {
      const date = dateCurrent.eq(i).attr('data-date');
      calendarArray.push(date);
    }

    keyArray = [];
    const keyCurrent = modal.find('input[name="terms"]:checked');
    for (let i = 0; i < keyCurrent.length; i++) {
      const key = keyCurrent.eq(i).attr('data-key-value');
      keyArray.push(key);
    }

    typeArray = [];
    const typeCurrent = modal.find('input[name="terms"]:checked');
    for (let i = 0; i < typeCurrent.length; i++) {
      const type = typeCurrent.eq(i).attr('data-type-value');
      if(type != '') typeArray.push(type);
    }

    conditionsArray = [];
    const conditionsCurrent = modal.find('input[name="terms"]:checked');
    for (let i = 0; i < conditionsCurrent.length; i++) {
      const conditions = conditionsCurrent.eq(i).siblings('p').text();
      conditionsArray.push(conditions);
    }

    if (calendarArray.length != 0 && keyArray.length != 0) {
      $('.c-btn.-diagnosis').css({
        'opacity': '',
        'pointer-events': '',
      });
    }else{
      $('.c-btn.-diagnosis').css({
        'opacity': '.5',
        'pointer-events': 'none',
      });
    }
  });

  modal.find('.c-btn.-diagnosis').click(function(e){
    e.preventDefault();

    Cookies.set('fairCalendar', JSON.stringify(calendarArray));
    Cookies.set('fairKey', JSON.stringify(keyArray));
    Cookies.set('fairType', JSON.stringify(typeArray));
    Cookies.set('fairConditions', JSON.stringify(conditionsArray));

    location = $(this).children('a').attr('href');
  });

  function _checkbox(){
    $('.checkboxBtn').on('click', function(){
      $('.c-modal-diagnosis .c-box-fair input[name="terms"]').prop('checked',false);
      $('.c-modal-diagnosis .c-calendar td[data-date]').removeClass('-current');
    });
  }
}