if(document.querySelector('#gmap')) {
  const script = document.createElement('script')
  script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyDmFBJJ_7Uf-RzZAGotkeOmVmgU5EyGykQ&callback=initMap'
  script.async = true

  document.head.appendChild(script);
}


window.initMap = () => {
  if(!document.querySelector('#gmap')) return

  const gmap = document.querySelector('#gmap')

  let map
  let marker
  let infowindow
  let markerData = {
    lat: 43.767401,
    lng: 142.311955,
    content: `<p class="c-box-shop__maps__link">ブルーミントンヒル<br><a href="https://goo.gl/maps/NfCGaP8vqCW65ThJA" target="_blank">Googleマップで見る&gt;&gt;</a></p>`
  }


  map = new google.maps.Map(gmap, {
    zoom: 16,
    center: { lat: markerData['lat'], lng: markerData['lng'] }
  })


  marker = new google.maps.Marker({
    position: {lat: markerData['lat'], lng: markerData['lng']},
    map: map,
    icon: {
      url: '../wp-content/themes/bmh/assets/images/access/icon_map.svg',
      scaledSize: new google.maps.Size(85, 70)
    }
  })


  infowindow = new google.maps.InfoWindow({
    content: markerData['content']
  })
  markerEvent()

  
  function markerEvent() {
    marker.addListener('click', function() {
      infowindow.open(map, marker)
    })
  }
}